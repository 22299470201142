.section-examples .container .col-sm-6 img{
  border-color: $info;
}
@include media-breakpoint-down(m) {
  .page-header{
    .content-center {
      &.brand {
        width: unset;
      }
    }
  }
}
